import React from "react";
import "./Experience.css";
import { experience, experienceData } from "./ExperienceData.js";

function Home() {
	return (
		<div id="experience" className="container-fluid ">
			<div className="row justify-content-md-center">
				<div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 align-self-center">
					<span>
						<h1>Experience</h1>
						{/* <img className="icon" src={icons.Experience} /> */}
					</span>

					<div className="headerUnderline"></div>
					<h3>{experience} Years of Experience</h3>
					{/* <p>{headline}</p> */}
					<div className="experienceMainContainer">
						{experienceData.map((val, key) => {
							return (
								<div key={key}>
									<div className="contentDivider"></div>
									<div key={key} className="experience-details">
										<div className="row margin-top40 margin-bottom40">
											<div className="media col-lg-5 col-md-5 col-sm-5 col-xl-5">
												<img id={val.id} className="mr-3" src={val.image} alt={val.company} />
												<div className="media-body">
													<h4>{val.company}</h4>
													<h5>{val.role}</h5>
													<h6>{val.timespan}</h6>
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-6 col-xl-6">
												{val.summary}
												<div className="skills-block">
													<ul className="content-skills">
														{val.skills.map((name, index) => (
															<li key={index}>{name}</li>
														))}
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Home;
