import React from "react";
import "./Education.css";
import { educationData, achievementsData } from "./EducationData.js";

function Home() {
	return (
		<div id="education_achievement" className="container-fluid">
			<div id="education" className="row justify-content-md-center">
				<div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
					<h1>Education</h1>
					<div className="headerUnderline"></div>
					<div className="educationmainContainer">
						{educationData.map((val, key) => {
							return (
								<div key={key}>
									<div className="contentDivider"></div>
									<div className="education-details">
										<div className="row margin-top30 margin-bottom30">
											<div className="justify-content-center align-items-center col-lg-6 col-md-12 col-sm-12 col-xl-6 media">
												<h5>
													<i className="mr-3 fa fa-graduation-cap"></i>
												</h5>
												<div className="media-body">
													<h4>{val.course}</h4>
												</div>
											</div>

											<div className="col-lg-6 col-md-12 col-sm-12 col-xl-6 ">
												<div className="media">
													<h5>
														<i className="mr-3 fa fa-university "></i>
													</h5>
													<h4>
														{val.college}, {val.University}
													</h4>
												</div>
												<div className="row detailsRow">
													<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 media">
														<h5>
															<i className="mr-3 fa fa-map-marker"></i>
														</h5>
														<div className="media-body">
															<h5>{val.location}</h5>
														</div>
													</div>
													<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 media">
														<h5>
															<i className="mr-3 fa fa-calendar"></i>
														</h5>
														<div className="media-body">
															<h5>{val.timespan}</h5>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<div id="achievements">
				<div className="row justify-content-md-center">
					<div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
						<h2>Academic Achievements</h2>
						<div className="headerUnderline"></div>

						<div className="achievementsmainContainer">
							{achievementsData.map((val, key) => {
								return (
									<div key={key}>
										<div className="contentDivider"></div>
										<div className="achievementsdetails">
											<div className="row margin-top30 margin-bottom30">
												<div className="justify-content-center align-items-center col-lg-6 col-md-6 col-sm-6 col-xl-6 media">
													<h5>
														<i className="mr-3 fas fa-award"></i>
													</h5>
													<div className="media-body">
														<h4>{val.name}</h4>
													</div>
												</div>
												<div className="col-lg-6 col-md-6 col-sm-6 col-xl-6">
													<div className="container">
														<div className="row">
															<div>
																<h5>{val.desc}</h5>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Home;
