interface Window {
	React: any;
	ReactDOM: any;
	AssistKit: {
		[key: string]: any;
	};
}

declare const NEXT_PUBLIC_HOST: string;

// Main implementation file
(function () {
	// Initialize AssistKit namespace if it doesn't exist
	if (!window.AssistKit) {
		window.AssistKit = {};
	}

	const loadScript = (src: string): Promise<void> => {
		return new Promise((resolve, reject) => {
			if (document.querySelector(`script[src="${src}"]`)) {
				resolve();
				return;
			}
			const script = document.createElement("script");
			script.src = src;
			script.async = true;
			script.onload = () => resolve();
			script.onerror = reject;
			document.head.appendChild(script);
		});
	};

	const init = async (): Promise<void> => {
		try {
			// First check if React/ReactDOM are already loaded
			if (!window.React || !window.ReactDOM) {
				// Load React first and wait for it
				await loadScript("https://unpkg.com/react@17/umd/react.production.min.js");
				// Wait a bit to ensure React is initialized
				await new Promise((resolve) => setTimeout(resolve, 100));
				// Then load ReactDOM
				await loadScript("https://unpkg.com/react-dom@17/umd/react-dom.production.min.js");
				// Wait again to ensure everything is ready
				await new Promise((resolve) => setTimeout(resolve, 100));
			}

			const script = document.currentScript || document.querySelector("script[data-chatbot-id]");
			const chatbotId = script?.getAttribute("data-chatbot-id");
			const container = document.getElementById("assistkit-chat");
			

			if (!container) {
				console.error("AssistKit: Chat container not found");
				return;
			}

			if (!chatbotId) {
				console.error("AssistKit: Chatbot ID not provided");
				return;
			}

			if (container) {
				// Force a reflow
				container.style.display = "none";
				requestAnimationFrame(() => {
					container.style.display = "block";
				});
			}

			// Now we can safely use React and ReactDOM
			const { React, ReactDOM } = window;
			// Import your ChatWidget component
			const ChatWidget = await import("./ChatWidget");
			// Render the widget
			const root = ReactDOM.createRoot(container);
			// console.log("-------NEXT_PUBLIC_HOST", NEXT_PUBLIC_HOST);
			root.render(
				React.createElement(ChatWidget.default, {
					chatbotID: chatbotId,
					API_URL: NEXT_PUBLIC_HOST,
				})
			);
		} catch (error) {
			console.error("AssistKit: Failed to initialize widget", error);
		}
	};

	// Initialize when DOM is ready
	if (document.readyState === "loading") {
		document.addEventListener("DOMContentLoaded", init);
	} else {
		init();
	}
})();
