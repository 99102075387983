import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import React from "react";
import "./Home.css";
import { description, heyImage, name, resumePdf, socialIcons } from "./HomeData.js";

function Home() {
	return (
		<div className="home container-fluid d-flex align-items-center justify-content-center" id="home">
			<div className="text-center content-wrapper w-100">
				{/* Hey and Wave Section */}
				<div className="mb-4 hey-section">
					<img className="heyImage" alt="Waiving hand" src={heyImage} />
					<span className="wave">👋</span>
				</div>

				{/* Name Section */}
				<div className="mb-4 name-section">
					<p className="mb-3 name">{name}</p>
				</div>

				{/* Description Section */}
				<div className="mb-4 description-section">
					<p className="description">{description}</p>
				</div>

				{/* Social Icons Section */}
				<div className="mb-4 social-media-div">
					{socialIcons.map((val, key) => (
						<a
							href={val.url}
							key={key}
							target="_blank"
							rel="noreferrer"
							className={"icon-button " + val.name}
						>
							<span style={{ backgroundColor: val.backgroundColor }}>
								<FontAwesomeIcon className="icon" icon={val.icon} />
							</span>
						</a>
					))}
				</div>

				{/* Resume Button Section */}
				<div className="resume-section">
					<a
						href={resumePdf}
						className="resumeLink"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Button className="resumeBtn" variant="contained">
							See My Resume
						</Button>
					</a>
				</div>
			</div>
		</div>
	);
}

export default Home;