import computerPortImage from "../../assets/images/computerPortImage.jpg";
import lvImage from "../../assets/images/lvImage.png";
import qaImage from "../../assets/images/quantium.jpeg";
import tcsImage from "../../assets/images/tcsImage.jpg";

var startDate = new Date("11/21/2016");
var today = new Date();
var milliDay = 1000 * 60 * 60 * 24; // a day in milliseconds;
var age = (today - startDate) / milliDay / 365;

export const experience = age.toFixed(1);

// export const headline = "Equipped with " + experience + " years of experience as a Data Analyst in BI & Analytical Space using Alteryx, Qlik tools, Python, Machine Learning and NLP. Also experienced in Data Modeling, Dashboard building, and insights generation with tools like QlikView, Qlik Sense and Python.";

export const experienceData = [
	{
		company: "Quantium Analytics",
		image: qaImage,
		id: "qaImage",
		role: "Senior Data Scientist",
		timespan: "Apr 2023 - Till Date",
		skills: ["Python", "Langchain", "Prompt Engineering", "RAG",],
		summary: "Actively enhancing a tool aimed at empowering retailers and suppliers to make smarter product assortment and customer decisions, while optimizing ranging and spacing strategies. Additionally, contributing to the development of an Attribute Accelerator tool that leverages Multi-Modal Large Language Models to streamline and accelerate product attribute generation.",
		// summary: "Building POC's for Banks across the globe using a core Banking product that helps banks make the most of their transactions. Involved in incorporating automation and process enhancements to the workflow with the use of Python, Scala and building advanced analytical solutions using the product results leveraging machine learning algorithms to solve client problems like Home Loan takeout thus helping showcase the true potential of the product. ",
	},
	{
		company: "Quantium Analytics",
		image: qaImage,
		id: "qaImage",
		role: "Data Scientist",
		timespan: "Jun 2021 - Mar 2023",
		skills: ["PySpark", "Python", "Scala"],
		summary: "Actively engaged in developing and implementing an innovative banking solution tailored for financial institutions globally. This product converts raw transaction data into actionable insights, facilitating data-driven decision-making and delivering precise assessments of lending quality.",
		// summary: "Building POC's for Banks across the globe using a core Banking product that helps banks make the most of their transactions. Involved in incorporating automation and process enhancements to the workflow with the use of Python, Scala and building advanced analytical solutions using the product results leveraging machine learning algorithms to solve client problems like Home Loan takeout thus helping showcase the true potential of the product. ",
	},
	{
		company: "LatentView Analytics",
		image: lvImage,
		id: "lvImage",
		role: "Data Analyst",
		timespan: "Oct 2019 - May 2021",
		skills: ["QlikView", "Alteryx", "NLP", "Django", "Python", "R", "Kubernetes", "SQL"],
		summary: "Worked collaboratively with client who is a automobile manufacturer for providing solutions to improve product and quality by analysing Recalls and concerns in manufacturing assembly line.",
	},
	{
		company: "Tata Consultancy Services",
		image: tcsImage,
		id: "tcsImage",
		role: "Data Analyst",
		timespan: "Sep 2018 - Sep 2019",
		skills: ["Dash by Plotly", "Python", "R"],
		summary: "Worked collaboratively with client who is a aircraft engine to build, analyse and automate distress identification and distress ranking of the fleet engine components.",
	},
	{
		company: "Tata Consultancy Services",
		image: tcsImage,
		id: "tcsImage",
		role: "ASE",
		timespan: "Nov 2017 - Aug 2018",
		skills: ["Python", "Machine Learning", "Classification"],
		summary: "Worked collaboratively with client who is a privately owned Indian development finance institution to build a machine learning model which will automate the loan approval process.",
	},
	{
		company: "Tata Consultancy Services",
		image: tcsImage,
		id: "tcsImage",
		role: "ASE - Trainee",
		timespan: "Nov 2016 - Oct 2017",
		skills: ["HTML", "CSS", "Struts", "Spring", "Java", "Hibernate", "Java Script"],
		summary: "Worked collaboratively with client who is an Indian government owned bank to design and develop Bill Payment system to POS and Tablet Devices which will handle Financial and Non Financial transactions.",
	},
	{
		company: "Computer Port IT Solutions",
		image: computerPortImage,
		id: "computerPortImage",
		role: "Summer Intern",
		timespan: "May 2015 - June 2015",
		skills: ["HTML", "CSS", "Java Script", "WordPress", "Photoshop", "Python"],
		summary: "Worked with team to update and optimise website which is built using WordPress and supported in web application deployment  and also in content creation for blogs",
	},
];
