import resumePDF from "../../assets/docs/resume.pdf";
import heyImag from "../../assets/images/home/hey1.svg";
import homePerson from "../../assets/images/home/man_waiving_hand.svg";

var startDate = new Date("11/21/2016");
var today = new Date();
var milliDay = 1000 * 60 * 60 * 24; // a day in milliseconds;
var age = (today - startDate) / milliDay / 365;

export const experience = age.toFixed(1);

export const resumePdf = resumePDF;
export const heyImage = heyImag;
export const homePersonImage = homePerson;

export const name = "I'm Sateesh";
export const description = "Welcome to my Devfolio!. I specialize in Large Language Models (LLMs) and GenAI development, with a strong foundation in machine learning and data science. I build sophisticated AI applications using LangChain, focusing on prompt engineering and efficient LLM integration. My expertise extends to Agents, RAG architectures, vector databases, and LLM optimization strategies.  I'm passionate about developing scalable LLM solutions and exploring innovative applications of generative AI. I hope my devfolio demonstrates both my technical capabilities. Thank you for visiting!";
export const socialIcons = [
	{
		name: "github",
		icon: ["fab", "github"],
		url: "https://github.com/sateeshyavarna",
		backgroundColor: "#333333",
	},
	{
		name: "linkedin",
		icon: ["fab", "linkedin"],
		url: "https://www.linkedin.com/in/sateeshyavarna/",
		backgroundColor: "#0e76a8",
	},
	{
		name: "mail",
		icon: ["fas", "envelope"],
		url: "mailto:yavarnasateesh@gmail.com",
		backgroundColor: "#ea4335",
	},
];
