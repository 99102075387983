import ageImage from "../../assets/images/aboutme/age.svg";
import birthdayImage from "../../assets/images/aboutme/birthday.svg";
import locationImage from "../../assets/images/aboutme/location.svg";
import sampleImage from "../../assets/images/aboutme/myphoto.png";
// import mailImage from "../../assets/images/aboutme/mail.svg";
// import phoneImage from "../../assets/images/aboutme/phone.svg";
// import skypeImage from "../../assets/images/aboutme/skype.svg";
import nationalityImage from "../../assets/images/aboutme/nationality.svg";
import AboutImage from "../../assets/images/navbar/aboutme.svg";

// import dockerImage from "../../assets/images/aboutme/skills/docker.svg";
import githubImage from "../../assets/images/aboutme/skills/github.svg";
import htmlImage from "../../assets/images/aboutme/skills/html.svg";
import jupyterImage from "../../assets/images/aboutme/skills/jupyter.svg";
// import kubernetesImage from "../../assets/images/aboutme/skills/kubernetes.svg";
import alteryxImage from "../../assets/images/aboutme/skills/alteryx.svg";
import cssImage from "../../assets/images/aboutme/skills/css.svg";
import plotlyImage from "../../assets/images/aboutme/skills/plotly.svg";
import postgresImage from "../../assets/images/aboutme/skills/postgres.svg";
import pythonImage from "../../assets/images/aboutme/skills/python.svg";
import qlikviewImage from "../../assets/images/aboutme/skills/qlikview.svg";
import reactImage from "../../assets/images/aboutme/skills/react.svg";
import scalaImage from "../../assets/images/aboutme/skills/scala.svg";

import postmanImage from "../../assets/images/aboutme/skills/postman.svg";
import ubuntuImage from "../../assets/images/aboutme/skills/ubuntu.svg";
import vscodeImage from "../../assets/images/aboutme/skills/vscode.svg";

import langchainImage from "../../assets/images/aboutme/skills/langchain.jpeg";
import nextjsImage from "../../assets/images/aboutme/skills/nextjs.jpeg";
import pysparkImage from "../../assets/images/aboutme/skills/pyspark.png";
import tailwindImage from "../../assets/images/aboutme/skills/tailwind.svg";


var today = new Date();
var milliDay = 1000 * 60 * 60 * 24; // a day in milliseconds;

var wrk_startDate = new Date("11/21/2016");
var age = (today - wrk_startDate) / milliDay / 365;
export const experience = age.toFixed(1);

var dob = new Date("06/13/1992");
var dobage = (today - dob) / milliDay / 365;
export const dob_age = Math.round(dobage);

export const icon = AboutImage;
export const photo = sampleImage;
export const name = "Sateesh Yavarna";
export const role = "Senior Data Scientist";
export const current_employer = "Quantium Analytics";
export const description = "With " + experience + " years of experience in the field, I am a skilled data scientist currently employed at " + current_employer + ". My broad technical skill-set allows me to tackle a variety of tasks and projects simultaneously. I have a strong ability to improvise, troubleshoot, take ownership, and continuously learn new skills on the job.";
export const bio = [
	{
		class: "birthday",
		title: "Birthday :",
		value: "13-06-1992",
		image: birthdayImage,
	},
	{
		class: "age",
		title: "Age :",
		value: dob_age + " years",
		image: ageImage,
	},
	{
		class: "location",
		title: "Location :",
		value: "Hyderabad",
		image: locationImage,
	},
	// {
	// 	class: "skype",
	// 	title: "Skype :",
	// 	value: "yavarnasateesh",
	// 	image: skypeImage,
	// },
	{
		class: "nationality",
		title: "Nationality :",
		value: "Indian",
		image: nationalityImage,
	},
];

export const skillsIcons = [
	// {
	// 	name: "Machine Learning",
	// 	image: machinelearningImage,
	// },
	{
		name: "Langchain",
		image: langchainImage,
	},
	{
		name: "Python",
		image: pythonImage,
	},
	{
		name: "PySpark",
		image: pysparkImage,
	},
	{
		name: "PostgresSQL",
		image: postgresImage,
	},
	{
		name: "Scala",
		image: scalaImage,
	},
	{
		name: "NextJS",
		image: nextjsImage,
	},
	{
		name: "React",
		image: reactImage,
	},
	{
		name: "Tailwind CSS",
		image: tailwindImage,
	},

	{
		name: "Plotly",
		image: plotlyImage,
	},
	{
		name: "Qlikview",
		image: qlikviewImage,
	},
	{
		name: "Alteryx",
		image: alteryxImage,
	},
	{
		name: "HTML",
		image: htmlImage,
	},
	{
		name: "CSS",
		image: cssImage,
	},
	// {
	// 	name: "Docker",
	// 	image: dockerImage,
	// },
	// {
	// 	name: "Kubernetes",
	// 	image: kubernetesImage,
	// },
];
export const toolsIcon = [
	{
		name: "Ubuntu",
		image: ubuntuImage,
	},
	{
		name: "Github",
		image: githubImage,
	},
	{
		name: "VS Code",
		image: vscodeImage,
	},
	{
		name: "Jupyter",
		image: jupyterImage,
	},
	{
		name: "Postman",
		image: postmanImage,
	},

];
